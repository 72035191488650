import jQuery from "jquery";
import remodal from "remodal";
import Swiper from 'swiper';
import lightgallery from 'lightgallery';
import axios from 'axios';

jQuery(function() {
    var  winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    jQuery('[data-remodal-id]').remodal({
        hashTracking: false
    });
    
    var menuItems = jQuery('.menu_main').find('.menu__link'),
        activeMenuItem = jQuery('.menu_main').find('.active');
  
    menuItems.hover(
      function (event) {
        var item = jQuery(event.target).parent();
        if (item.hasClass('active')) {
          return false;
        }
        // remove current
        if (jQuery('.menu_main').find('.active').length) {
          jQuery('.menu_main').find('.active').removeClass('active');
          activeMenuItem.addClass('active')
        }
        // set current
        item.addClass('active');
      },
      function () {
        jQuery('.menu_main').find('.active').removeClass('active');
        activeMenuItem.addClass('active')
      }
    );
    //open mobile menu nav
    jQuery('.js-nav-open').on('click', function(e){
      e.preventDefault();
      jQuery(this).toggleClass('is-active');
      jQuery('.logo').toggleClass('is-active');
      jQuery('body').toggleClass('is-navbar-open');
    });
    
    var mainSlider = new Swiper ('.slider_main', {
        autoplay: {
            delay: 8000,
        },
        disableOnInteraction: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        a11y: {
            enabled: false
        }
    });
    jQuery('.slider_main').on('mouseenter', function(e){
      mainSlider.autoplay.stop();
    });
    jQuery('.slider_main').on('mouseleave', function(e){
      mainSlider.autoplay.start();
    });
    
    var reviewsSlider = new Swiper ('.slider_reviews', {
        loop: false,
        autoplay: {
          delay: 8000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        a11y: {
            enabled: false
        },
    });
    jQuery('.slider_reviews').on('mouseenter', function(e){
      reviewsSlider.autoplay.stop();
    });
    jQuery('.slider_reviews').on('mouseleave', function(e){
      reviewsSlider.autoplay.start();
    });
  
    var gallery = jQuery(".gallery").lightGallery({
      selector: '.gallery__link'
    });
    
  function toogleClassBtn (btn) {
    jQuery('.list_filter .list__item').children().removeClass('active');
    jQuery(btn).addClass('active');
  }
  
  function filterPhotos(album_id) {
    jQuery(".gallery-container").addClass('is-loading');
    axios.get('/filterPhotos/'+album_id)
      .then(function(response) {
        if(response.data.html) {
          jQuery(".gallery").children().remove();
          jQuery(".gallery").append(response.data.html);
          gallery.data('lightGallery').destroy(true);
          gallery = jQuery(".gallery").lightGallery({
            selector: '.gallery__link'
          });
        } else  {
          jQuery(".gallery").children().remove();
          jQuery(".gallery").append('<li><h4>Пока в альбоме нет фото</h4></li>');
        }
        jQuery(".gallery-container").removeClass('is-loading');
        if(!response.data.pagination) {
          jQuery(".pagination").hide()
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  function allPhotos() {
    jQuery(".gallery-container").addClass('is-loading');
    axios.get('/allPhotos/')
      .then(function(response) {
        if(response.data.html) {
          jQuery(".gallery").children().remove();
          jQuery(".gallery").append(response.data.html);
          gallery.data('lightGallery').destroy(true);
          gallery = jQuery(".gallery").lightGallery({
            selector: '.gallery__link'
          });
        } else {
          var itemsEmpty = '<li class="gallery__item"><h4>Пока нет фото</h4></li>';
          jQuery(".gallery").children().remove().append(itemsEmpty);
        }
        jQuery(".gallery-container").removeClass('is-loading');
        if(response.data.pagination) {
          jQuery(".pagination").show()
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  jQuery('.list_filter .button_filter').on('click', function(event) {
    event.preventDefault();
    toogleClassBtn(this);
    var id = jQuery(this).attr('data-album');
    console.log(id);
    filterPhotos(id);
  });
  jQuery('.list_filter .button_all').on('click', function(event) {
    event.preventDefault();
    toogleClassBtn(this);
    allPhotos();
  });
  
  function filterAudio(audio_id) {
    axios.get('/filterAudio/'+audio_id)
      .then(function(response) {
        var audio = {};
        if(response.data.title && response.data.text) {
          var instModal = jQuery('[data-remodal-id=audio]').remodal(),
            $modalTitle = jQuery(instModal.$modal[0]).find('.modal__header .title'),
            $modalBody = jQuery(instModal.$modal[0]).find('.modal__body');
          $modalTitle.html(response.data.title);
          $modalBody.html(response.data.text);
          instModal.open();
        }
      })
      .catch(function(error) {
        console.log(error);
      })
  }
  
  jQuery('.js-read-audio').on('click',function () {
    filterAudio(jQuery(this).attr('data-audio'));
  });
    
    var $buttonTop= jQuery('.js-top');
    $buttonTop.on('click', function () {
        jQuery('body, html').animate({
          scrollTop: 0
        }, 800);
        return false;
    });
    jQuery(window).on('load scroll', function() {
        var winTop = jQuery(window).scrollTop();
        if (winTop > 450) {
          $buttonTop.fadeIn();
        }
        else {
          $buttonTop.fadeOut(300);
        }
    });
    
  //audio players
  const players = Plyr.setup('.js-player');

  //lazy load
  if(jQuery('.lazy').length){
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.expand = 7;
    lazySizesConfig. expFactor = 1.4;
    lazySizesConfig.minSize = 'auto'
  }

});
